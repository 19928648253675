import { useState } from "react";
import { message, Alert, Row, Col, Card, Form, Input, Button } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";

export function ForgotPassword() {
  const { t } = useTranslation("forgotPassword");

  const passwordReset = useAuth()!.passwordReset;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const msg = t("please");

  async function handleLogin(values: any) {
    setError("");
    try {
      setLoading(true);
      await passwordReset(values.email);
      message.success(t("check"));
    } catch (e) {
      setError(t("fail"));
    }
    setLoading(false);
  }

  return (
    <Row
      className="rowForgotPwd"
      justify="center"
      align="middle"
      wrap
      gutter={[16, 16]}
    >
      <Col xs={24} sm={16} md={14} lg={10} xl={6}>
        <Card title={t("forgot")} bordered={false}>
          {error && <Alert message={error} type="error" />}
          <Form
            name="normal_forgotPassword"
            className="forgotPassword-form"
            initialValues={{ remember: true }}
            onFinish={handleLogin}
          >
            <Form.Item name="email" rules={[{ required: true, message: msg }]}>
              <Input
                className="site-form-item-icon"
                prefix={<MailOutlined />}
                placeholder={t("input")}
              />
            </Form.Item>
            <Form.Item>
              <Button
                id="submitButton"
                type="primary"
                disabled={loading}
                htmlType="submit"
                className="login-form-button"
              >
                {t("resetpwd")}
              </Button>
              <p id="clickPwd">
                {t("click")} <Link to="/login"> {t("here")} </Link>{" "}
              </p>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

import { Statistic, Card, Typography, Divider, Layout } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { useAuth } from "../context/AuthContext";
import { useProduct } from "../context/productContext";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

export function Profile() {
  const { t } = useTranslation("profile");
  const userInfo = useAuth()!.userInfo.userInfo;
  const numberOfProduct = useProduct()!.userProductList.size;

  return (
    <Layout>
      <div className="flex-container-profile">
        <div className="flex-profile-card">
          <Card>
            <Title level={2} type="secondary">
              {t("PersonalInfo")}
            </Title>
            <Divider />

            <Title>
              {userInfo && userInfo.name} {userInfo && userInfo.surname}{" "}
            </Title>

            <p>
              <span>
                {" "}
                <Text type="secondary">Email : </Text>
              </span>
              {userInfo && userInfo.email}
            </p>
            <p>
              <span>
                {" "}
                <Text type="secondary">{t("phone")} : </Text>
              </span>
              {userInfo && userInfo.phone}
            </p>
          </Card>
        </div>
        <div className="flex-profile-card">
          <Card>
            <Title level={2} type="secondary">
              {" "}
              {t("CompanyInformation")}
            </Title>
            <Divider />
            <Title>{userInfo && userInfo.companyName} </Title>
            <p>
              <span>
                {" "}
                <Text type="secondary">{t("Adress")} : </Text>
              </span>
              {userInfo && userInfo.address}
            </p>
            {userInfo?.addresseExtra && (
              <p>
                <span>
                  {" "}
                  <Text type="secondary">{t("Adress")} : </Text>
                </span>
                {userInfo.addresseExtra}
              </p>
            )}
            <p>
              <span>
                {" "}
                <Text type="secondary">{t("PostalCode")} : </Text>
              </span>
              {userInfo && userInfo.postalCode}
            </p>
            <p>
              <span>
                {" "}
                <Text type="secondary">{t("City")} : </Text>
              </span>
              {userInfo && userInfo.city}
            </p>
            <p>
              <span>
                {" "}
                <Text type="secondary">{t("Country")} : </Text>
              </span>
              {userInfo && userInfo.country}
            </p>
            <p>
              <span>
                {" "}
                <Text type="secondary">{t("Siret")} : </Text>
              </span>
              {userInfo && userInfo.companyNumber}
            </p>
          </Card>
        </div>
        <div className="flex-profile-card">
          <Card>
            <Title level={2} type="secondary">
              {" "}
              {t("Products")}{" "}
            </Title>
            <Divider />
            <div className="product-profile-card">
              <div>
                <Statistic
                  title={t("Products")}
                  value={numberOfProduct}
                  prefix={<AppstoreAddOutlined />}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Layout>
  );
}
<Divider>You</Divider>;

import { IUserProduct } from "../Interface/IProduct";
import { Divider, Modal, Image, Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import Title from "antd/lib/typography/Title";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { minImgDefault } from "./utils/default";
import { useEffect, useState } from "react";

interface ModalProps {
  visible: boolean;
  hide: () => void;
  selectedProduct: IUserProduct | null;
}

export function ModalInfoProduct(props: ModalProps) {
  const { t, i18n } = useTranslation("productInfo");

  const data = props.selectedProduct;
  const [selected, setSelected] = useState<IUserProduct | null>(null);
  useEffect(() => {
    setSelected(data);
    return () => {
      if (!props.visible) {
        setSelected(null);
      }
    };
  }, [data, selected, props.visible]);

  const hide = props.hide;
  const closingModal = () => {
    hide();
  };

  return (
    <Modal
      onCancel={closingModal}
      footer={null}
      centered
      keyboard={true}
      visible={props.visible}
      width={1000}
      mask={true}
      maskClosable={true}
      maskStyle={{ backgroundColor: "transparent" }}
      bodyStyle={{ background: "F5F5F5" }}
    >
      <div className="divModal">
        <div className="divTitle">
          <Title id="titleModalProduct">
            
            {selected ? selected.publicName : ""}
          </Title>
        </div>

        <div className="divSerialNum">
          <p id="pDivSerialNum1">{t("serialNumber")} </p>
          <p id="pDivSerialNum2">{selected ? selected.serialNumber : ""}</p>
        </div>

        <div className="divTop">
          <div>
            <p>{selected && selected.description}</p>
            <div>
              <Button
                type="text"
                icon={<InfoCircleOutlined style={{ color: "white" }} />}
                size={"large"}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={selected ? selected.userGuideUrl : ""}
                >
                  {t("notice")}
                </a>
              </Button>
            </div>
          </div>

          <div>
            <Image
              style={{ maxWidth: "250px" }}
              preview={false}
              width={"100%"}
              src={selected ? selected.photoUrl : minImgDefault}
            />
          </div>
        </div>

        <Divider
          style={{
            marginTop: "3vh",
            marginBottom: "3vh",
            borderWidth: 3,
            borderColor: "#F8AD1C",
          }}
        >
          {t("technical")}
        </Divider>

        <div>
          <div className="tabModal">
            <Row justify={"space-between"} gutter={[24, 24]}>
              <Row gutter={[12, 24]}>
                <Col className="leftColumn" >
                  <p>{t("reference")}</p>
                  <br />
                  <p>{t("radio")} </p>
                  <br />
                  <p>{t("activation")} </p>
                  <br />
                  <p>{t("warranty")}</p>
                  <br />
                  <p>{t("fw")} </p>
                  <br />
                  <p>{t("hw")} </p>
                </Col>
                <Col className="rightColumn">
                  <p>{selected ? selected.reference : ""}</p>
                  <br />
                  <p>{selected ? selected.radio : ""}</p>
                  <br />
                  <p>
                    {selected
                      ? selected.activationDate
                          .toDate()
                          .toLocaleString(i18n.language)
                          .split(",")[0]
                      : ""}
                  </p>
                  <br />
                  <p>
                    {selected
                      ? selected.endOfGuarantee
                          .toDate()
                          .toLocaleString(i18n.language)
                          .split(",")[0]
                      : ""}
                  </p>
                  <br />
                  <p>{selected ? selected.versionFw : ""}</p>
                  <br />
                  <p>{selected ? selected.versionHw : ""}</p>
                </Col>
              </Row>

              <Row gutter={[12, 24]}>
                <Col className="leftColumn">
                  <p>{t("powerSupply")}</p>
                  <br />
                  <p> {t("range")}</p>
                  <br />
                  <p> {t("temperature")}</p>
                  <br />
                  <p> {t("power")}</p>
                  <br />
                  <p>{t("size")}</p>
                  <br />
                </Col>
                <Col className="rightColumn">
                  <p>
                    
                    {selected ? selected.powerSupply : ""}V AC ~
                    {selected ? selected.frequency : ""}Hz
                  </p>
                  <br />
                  <p>
                    
                    {selected ? selected.range : ""}m {t("inside")}
                  </p>
                  <br />
                  <p>
                    
                    {selected ? selected.temperature?.minTemperature : 0}°C
                    {t("to")}
                    {selected ? selected.temperature?.maxTemperature : 0}°C
                  </p>
                  <br />
                  <p>
                    {selected ? selected.output : ""} x
                    {selected ? selected.power : ""} W
                  </p>
                  <br />
                  <p>
                    
                    {selected ? selected.dimension?.length : 0}({t("l")}) x
                    {selected ? selected.dimension?.width : 0}({t("w")}) x
                    {selected ? selected.dimension?.height : 0}({t("h")})
                  </p>
                  <br />
                </Col>
              </Row>
            </Row>
          </div>
        </div>
        <Divider
          style={{
            marginTop: "3vh",
            marginBottom: "3vh",
            borderWidth: 3,
            borderColor: "#F8AD1C",
          }}
        >
          {t("support")}
        </Divider>
        <div className="divBottom">
          <Link to={`/sav?id=${selected && selected.serialNumber}`}>
            <Button className="buttonSupport" type="text">
              {t("assistance")}
            </Button>
          </Link>

          <Button className="buttonSupport" type="link">
            <a
              style={{ color: "black" }}
              target="_blank"
              rel="noreferrer"
              href={selected ? selected.wiring : ""}
            >
              {t("cable")}
            </a>
          </Button>

          <Button className="buttonSupport" type="link">
            <a
              style={{ color: "black" }}
              target="_blank"
              rel="noreferrer"
              href={
                "https://nodon.pro/en/produits/zigbee-pro-on-off-lighting-relay-switch/"
              }
            >
              {t("productSheet")}
            </a>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export default function AuthRoute({ component: Component, ...rest }: any) {
  const user = useAuth()!.currentUser;

  return (
    <Route
      {...rest}
      render={(props) => {
        return user ? <Redirect to="/" /> : <Component {...props} />;
      }}
    ></Route>
  );
}

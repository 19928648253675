import { useState } from "react";
import { Checkbox,Typography,Divider,Alert,Row,Col,Card,Form,Input,Button } from "antd";
import { MailOutlined,LockOutlined,UserOutlined,PhoneOutlined,HomeOutlined,TrademarkOutlined,FlagOutlined } from "@ant-design/icons";
import { useAuth } from "../context/AuthContext";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IUser } from "../Interface/IUser";
import logo from "../images/logo_light_nodonpro.svg";

const { Title } = Typography;

export function Signup() {
  const { t, i18n } = useTranslation("signup");

  const { signup } = useAuth()!;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const msgName = t("msgName");
  const msgEmail = t("msgEmail");
  const msgSurname = t("msgSurname");
  const msgPhone = t("msgPhone");
  const msgCie = t("msgCie");
  const msgAdress = t("msgAdress");
  const msgCp = t("msgCp");
  const msgCountry = t("msgCountry");
  const msglegal = t("msglegal");
  const msgCity = t("msgCity");
  const msgPwd = t("msgPwd");
  const msgRepeatPwd = t("msgRepeatPwd");

  async function handleSubmit(user: IUser) {
    setError("");
    if (user.password !== user.passwordRepeat) {
      return setError(t("pwd"));
    }
    if (user)
      try {
        user.language = i18n.language;
        setLoading(true);
        await signup(user);
        history.push("/");
      } catch (error) {
        if (error.message) {
          setError(error.message);
        } else {
          setError(t("fail"));
        }
      }
    setLoading(false);
  }

  return (
    <Row
    className="rowSignup"
      justify="center"
      align="middle"
    >
      <Col xs={24} sm={16} md={14} lg={14} xl={10}>
        <div className="logo-login" >
          <img src={logo} alt="logo" />
        </div>
        <Card className="card-signup" bordered={false}>
          <Title className="textAlign" level={3}>
            {t("create")}
          </Title>
          <Divider />
          <Title className="textAlign" level={4}>
            {t("account")}
          </Title>
          {error && <Alert message={error} type="error" />}
          <Form
            name="normal_signup"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: msgName }]}
                  hasFeedback
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t("placeholderName")}
                  />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[{ required: true, message: msgEmail }]}
                  hasFeedback
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="surname"
                  rules={[{ required: true, message: msgSurname }]}
                  hasFeedback
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t("placeholderSurname")}
                  />
                </Form.Item>

                <Form.Item
                  name="phone"
                  rules={[{ required: true, message: msgPhone }]}
                  hasFeedback
                >
                  <Input
                    prefix={<PhoneOutlined className="site-form-item-icon" />}
                    placeholder={t("placeholderPhone")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Title className="textAlign" level={4}>
              {t("cie")}
            </Title>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="companyName"
                  rules={[{ required: true, message: msgCie }]}
                  hasFeedback
                >
                  <Input
                    prefix={<HomeOutlined className="site-form-item-icon" />}
                    placeholder={t("placeholderCie")}
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  rules={[{ required: true, message: msgAdress }]}
                  hasFeedback
                >
                  <Input
                    prefix={<HomeOutlined className="site-form-item-icon" />}
                    placeholder={t("placeholderAddress")}
                  />
                </Form.Item>
                <Form.Item
                  name="postalCode"
                  rules={[{ required: true, message: msgCp }]}
                  hasFeedback
                >
                  <Input
                    prefix={<HomeOutlined className="site-form-item-icon" />}
                    placeholder={t("placeholderPostalCode")}
                  />
                </Form.Item>
                <Form.Item
                  name="country"
                  rules={[{ required: true, message: msgCountry }]}
                  hasFeedback
                >
                  <Input
                    prefix={<FlagOutlined className="site-form-item-icon" />}
                    placeholder={t("placeholderCountry")}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="companyNumber"
                  rules={[{ required: true, message: msglegal }]}
                  hasFeedback
                >
                  <Input
                    prefix={
                      <TrademarkOutlined className="site-form-item-icon" />
                    }
                    placeholder={t("placeholderLegal")}
                  />
                </Form.Item>
                <Form.Item name="addresseExtra" initialValue={""} rules={[{}]}>
                  <Input
                    prefix={<HomeOutlined className="site-form-item-icon" />}
                    placeholder={t("placeholderAdditional")}
                  />
                </Form.Item>
                <Form.Item
                  name="city"
                  rules={[{ required: true, message: msgCity }]}
                  hasFeedback
                >
                  <Input
                    prefix={<HomeOutlined className="site-form-item-icon" />}
                    placeholder={t("placeholderCity")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Title className="textAlign" level={4}>
              {t("connexion")}
            </Title>

            <Form.Item
              name="password"
              rules={[{ required: true, message: msgPwd }]}
              hasFeedback
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={t("placeholderPwd")}
              />
            </Form.Item>
            <Form.Item
              name="passwordRepeat"
              rules={[{ required: true, message: msgRepeatPwd }]}
              hasFeedback
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={t("placeholderRepeat")}
              />
            </Form.Item>
            <Form.Item
              initialValue={false}
              className="checkSignup"
              name="nodonExpert"
              valuePropName="checked"
            >
              <Checkbox>{t("training")}</Checkbox>
            </Form.Item>
            <Form.Item
              initialValue={false}
              className="checkSignup"
              name="nodonMap"
              valuePropName="checked"
            >
              <Checkbox>{t("proMap")}</Checkbox>
            </Form.Item>
            <Form.Item className="checkSignup">
              {t("form")}{" "}
              <a rel="noreferrer" href="https://nodon.fr/cgv/" target="_blank">
                {" "}
                {t("cgv")}
              </a>{" "}
              {t("form2")}{" "}
              <a
                rel="noreferrer"
                href="https://nodon.fr/politique-de-confidentialite/"
                target="_blank"
              >
                {" "}
                {t("politique")}
              </a>
            </Form.Item>
            <Form.Item>
              <Button
                disabled={loading}
                type="primary"
                htmlType="submit"
                className="signup-form-button"
              >
                {t("signup")}
              </Button>
              {t("alreadyAccount")} <Link to="/login"> {t("log")}</Link>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

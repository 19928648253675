import { IUser } from "../Interface/IUser";
import { Typography, Card } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
interface IProfileCard {
  userInfo: IUser | null;
}

export function MyProfileCard(props: IProfileCard) {
  const { t } = useTranslation("myProfileCard");

  const history = useHistory();

  const onClickCard = () => {
    history.push("/products");
  };

  return (
    <Card
    className="cardProfileCardHome"
      onClick={onClickCard}
      hoverable
      bodyStyle={{ minWidth: "30vh", height: "50vh", padding: "0" }}
    >
      <div className="background-profile-card">
        <div className="div-background-profile-card">
          <Title className="title-background-profile-card"> {t("myProducts")} </Title>
        </div>
      </div>
    </Card>
  );
}

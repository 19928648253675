import React from "react";
import ReactDOM from "react-dom";
// import 'antd/dist/antd.less';
import "./App.less";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

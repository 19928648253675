import { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Login } from "./components/login";
import { Signup } from "./components/signup";
import { Dashboard } from "./components/dashboard";
import { ForgotPassword } from "./components/forgotPassword";
import { Layout } from "antd";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "./components/privateRoute";
import AuthRoute from "./components/authRoute";

export default function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback="loading">
        <Layout>
          <Router>
            <AuthProvider>
              <Switch>
                <AuthRoute path="/signup" component={Signup} />
                <AuthRoute path="/forgot-password" component={ForgotPassword} />
                <AuthRoute path="/login" component={Login} />
                <PrivateRoute path="/" component={Dashboard} />
              </Switch>
            </AuthProvider>
          </Router>
        </Layout>
      </Suspense>
    </I18nextProvider>
  );
}

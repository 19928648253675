import { useState, useEffect } from "react";
import { Typography, Menu, Image, Layout, Alert } from "antd";
import { useAuth } from "../context/AuthContext";
import { Link,Switch,Route,useHistory,useRouteMatch,useLocation } from "react-router-dom";
import { MailOutlined,LogoutOutlined,HomeOutlined,FileProtectOutlined,AppstoreAddOutlined,QuestionCircleOutlined } from "@ant-design/icons";
import { useWindowSize } from "./utils/windowsSize";
import { Home } from "./home";
import { Products } from "./products";
import { Sav } from "./sav";
import { Profile } from "./profile";
import { ProductsProvider } from "../context/productContext";
import { NotFound } from "./notFound";
import { useTranslation } from "react-i18next";
import logo from "../images/logo_nodonpro.png";
import { ParametersProvider } from "../context/parametersContext";

const { Sider } = Layout;
const { Title } = Typography;
const { SubMenu } = Menu;

export function Dashboard() {
  const { t } = useTranslation("dashboard");

  const logout = useAuth()!.logout;
  const history = useHistory();
  const userInfo = useAuth()!.userInfo.userInfo;
  const urlLocation = useLocation();
  const size = useWindowSize();
  const [error, setError] = useState("");
  const [showSlider, setShowSlider] = useState(true);
  const [smallSlider, setSmallSlider] = useState(true);
  const [smallWindowsHeight, setSmallWindowsHeight] = useState(false);
  const [userName, setUserName] = useState("");
  let { path } = useRouteMatch();
  const [showSider, setShowSider] = useState(true);
  const [siderWidth, setSiderWidth] = useState(250);

  const BottomMenu = () => (
    <div className="bottom_dash">
      <div>
        <Menu theme={"dark"}>
          <Menu.Item
            onClick={handleLogout}
            key="link"
            icon={<LogoutOutlined />}
          >
            {t("logout")}
          </Menu.Item>
          <SubMenu key="sub1" icon={<FileProtectOutlined />} title={t("info")}>
            <Menu.Item key="1" className="menu_bottom_cgv1">
              <a rel="noreferrer" href="https://nodon.fr/cgv/" target="_blank">
                {t("cgv")}
              </a>
            </Menu.Item>
            <Menu.Item key="2" className="menu_bottom_cgv1">
              <a
                rel="noreferrer"
                href="https://nodon.fr/politique-de-confidentialite/"
                target="_blank"
              >
                {" "}
                {t("policy")}
              </a>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
      <div className="logo-login">
        <Image
          preview={false}
          src={logo}
          alt="logo"
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (!userInfo) {
      setUserName("");
    } else if (!smallSlider) {
      setUserName(userInfo.name + " " + userInfo.surname);
    } else {
      setUserName(userInfo.name.charAt(0) + "." + userInfo.surname.charAt(0));
    }
  }, [userInfo, setUserName, smallSlider]);

  useEffect(() => {
    if (size.height) {
      if (size.height < 650) {
        if (!smallWindowsHeight) {
          setSmallWindowsHeight(true);
        }
      } else {
        if (smallWindowsHeight) {
          setSmallWindowsHeight(false);
        }
      }
    }
  }, [size.height, smallWindowsHeight]);

  useEffect(() => {
    if (
      urlLocation.pathname !== "/" &&
      urlLocation.pathname !== "/products" &&
      urlLocation.pathname !== "/sav" &&
      urlLocation.pathname !== "/account"
    ) {
      setShowSlider(false);
    } else {
      setShowSlider(true);
    }
  }, [urlLocation, setShowSlider]);

  async function handleLogout() {
    const errorMessage = t("error1");
    try {
      await logout();
      history.push("/login");
    } catch (error) {
      setError(errorMessage);
    }
  }

  return (
    <ProductsProvider>
      <ParametersProvider>
      {showSlider && (
        <Sider
          onBreakpoint={(broken) => {
            setSmallSlider(broken);
            if (broken) setSiderWidth(170);
            else {
              setSiderWidth(250);
              setShowSider(false);
            }
          }}
          onCollapse={(collapsed, type) => {
            if (type === "clickTrigger") {
              setShowSider(!showSider);
            }
          }}
          breakpoint="xl"
          collapsed={showSider}
          collapsedWidth="0"
          width={siderWidth}
          className="siderDashboard"
        >
          <div>
            <div id="divTopDashboard">
              {!smallSlider && !smallWindowsHeight && (
                <Title level={1} >{t("welcome")}</Title>
              )}
              {userName && (
                <Title level={4} >
                  {userName.toUpperCase()}{" "}
                </Title>
              )}
              {userInfo?.companyName && (
                <Title level={5} >
                  {userInfo?.companyName.toUpperCase()}
                </Title>
              )}
            </div>
            {error && <Alert message={error} type="error" />}

            <div id="navDashboard">
              <Menu
                selectedKeys={[urlLocation.pathname]}
                mode={"vertical"}
                theme={"dark"}
              >
                <Menu.Item key="/" icon={<HomeOutlined />}>
                  <Link to="/"> {t("home")} </Link>
                </Menu.Item>
                <Menu.Item key="/products" icon={<AppstoreAddOutlined />}>
                  <Link to="/products"> {t("myProducts")}</Link>
                </Menu.Item>
                <Menu.Item key="/sav" icon={<QuestionCircleOutlined />}>
                  <Link to="/sav"> {t("SAV")}</Link>
                </Menu.Item>
                <Menu.Item key="/account" icon={<MailOutlined />}>
                  <Link to="/account"> {t("myprofile")}</Link>
                </Menu.Item>
              </Menu>
              {!showSider ? <BottomMenu /> : null}
            </div>
          </div>
        </Sider>
      )}
      <Switch>
        <Route exact path={path}>
          <Home />
        </Route>
        <Route exact path={"/products"}>
          <Products />
        </Route>
        <Route exact path={"/sav"}>
          <Sav />
        </Route>
        <Route exact path={"/account"}>
          <Profile />
        </Route>

        <Route component={NotFound} />
      </Switch>
      </ParametersProvider>
    </ProductsProvider>
  );
}

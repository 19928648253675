import { useEffect, useState } from "react";
import { Typography, Table, Button, Image } from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { PlusCircleOutlined, AntDesignOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useParameters} from "../context/parametersContext";
import { useProduct } from "../context/productContext";
import firebase from "../myFirebase";
import {  IUserProduct } from "../Interface/IProduct";
import { minImgDefault } from "./utils/default";
import { ModalAddProduct } from "./addProductModal";
import { ModalInfoProduct } from "./productInfo";
import { useTranslation } from "react-i18next";
import Avatar from "antd/lib/avatar/avatar";
import { Carousel } from "antd";
import { useWindowSize } from "./utils/windowsSize";


const { Title } = Typography;


export function Products() {
  const { t, i18n } = useTranslation("products");

  const [smallWindowsHeight, setSmallWindowsHeight] = useState(false);
  
  const size = useWindowSize(); 

  const userDataSourceProducts = useProduct()?.userDataSourceProducts;

  const parametersList = useParameters()?.param;

  const [visible, setVisible] = useState(false);

  const [visibleInfo, setVisibleInfo] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState<IUserProduct | null>(
    null
  );

  const showInfoProduct = ( product: IUserProduct | null) => {
    setSelectedProduct(product);
    setVisibleInfo(true);
  };

  const hideInfoProduct = () => {
    setVisibleInfo(false);
  };

  const showAddProduct = () => {
    setVisible(true);
  };

  const hideAddProduct = () => {
    setVisible(false);
  };

  //T
  const imgCarousel = [
    {
      id: 1,
      uri: parametersList?.urlImage.sin41
    },
    {
      id: 2,
      uri: parametersList?.urlImage.sin42
    },
    {
      id: 3,
      uri: parametersList?.urlImage.sin4rs
    },
  ];
  

  //@ts-ignore
  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "partImage",
      responsive: ["md"] as Breakpoint[],
      ellipsis: true,
      width: "30px",
      align: "center" as "center",
      render: (r: any, image: IUserProduct) => (
        <Avatar
          size={{ xs: 24, sm: 36, md: 40, lg: 44, xl: 46, xxl: 50 }}
          icon={<AntDesignOutlined />}
          shape="square"
          src={image.photoUrl ? image.photoUrl : minImgDefault}
        />
      ),
    },
    {
      title: t("nameProduct"),
      key: "name",
      ellipsis: true,
      dataIndex: "productName",
      width: "100px",
      align: "center" as "center",
      render: (r: string, product: IUserProduct) => (
        <>
          <Button onClick={() => showInfoProduct( product)} type="link">
            {product.publicName ? product.publicName : product.productName}
          </Button>
          <ModalInfoProduct
            selectedProduct={selectedProduct}
            visible={visibleInfo}
            hide={hideInfoProduct}
          />
        </>
      ),
    },
    {
      title: "Radio",
      key: "radio",
      dataIndex: "radio",
      ellipsis: true,
      responsive: ["xxl"] as Breakpoint[],
      width: "80px",
      align: "center" as "center",
      render: (r: string) => r.toUpperCase(),
    },
    {
      title: t("warranty"),
      responsive: ["xxl"] as Breakpoint[],
      key: "endWarranty",
      dataIndex: "endOfGuarantee",
      ellipsis: true,
      width: "80px",
      align: "center" as "center",
      render: (r: firebase.firestore.Timestamp) =>
        r.toDate().toLocaleString(i18n.language).split(",")[0],
    },

    {
      title: t("guideTab"),
      key: "guide",
      dataIndex: "guide",
      width: "80px",
      align: "center" as "center",
      render: (r: string, product: IUserProduct) => (
        <Button type="text">
          <a
            target="_blank"
            rel="noreferrer"
            href={
              product.userGuideUrl ? product.userGuideUrl : product.userGuideUrl
            }
          >
            {t("userGuide")}{" "}
          </a>{" "}
        </Button>
      ),
    },

    {
      title: "Version",
      responsive: ["xxl"] as Breakpoint[],
      key: "version",
      dataIndex: "versionFw",
      width: "60px",
      ellipsis: true,
      align: "center" as "center",
    },
    {
      title: t("SAV"),
      dataIndex: "",
      key: "sav",
      width: "40px",
      align: "center" as "center",
      responsive: ["md"] as Breakpoint[],
      render: (r: string, product: IUserProduct) => (
        <Link to={`/sav?id=${product.serialNumber}`}>
          <Button type="text">{t("SAV")}</Button>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    if (size.height) {
      if (size.height < 650) {
        if (!smallWindowsHeight) {
          setSmallWindowsHeight(true);
        }
      } else {
        if (smallWindowsHeight) {
          setSmallWindowsHeight(false);
        }
      }
    }
  }, [size.height, smallWindowsHeight]);

  if (userDataSourceProducts) {
    return (
      <div className="divProduct">
        <div className="topProduct">
          <div>
            <Title level={2}> {t("myProducts")}</Title>
          </div>

          <Button
            className="buttonProduct"
            type="primary"
            onClick={showAddProduct}
            icon={<PlusCircleOutlined />}
            size={"large"}
          >
            {t("add")}
          </Button>
          <ModalAddProduct visible={visible} hide={hideAddProduct} />
        </div>
          <Table
            className="table-products"
            size="middle"
            pagination={false}
            columns={columns}
            rowKey={(product) => product.id}
            dataSource={userDataSourceProducts}
          />
      </div>
    );
  }

  return (
    <>
      {!smallWindowsHeight ? (
        <div className="withoutProduct">
          <div className="titleWithoutProduct">
            <Title level={2}> {t("myProducts")}</Title>
          </div>
          <div className="divCarousel">
            <Carousel autoplay effect="fade">
            {imgCarousel.map (img => (
              <div key={img.id} className="imgCarousel" >
                <Image preview={false} src={img.uri} alt="" />     
              </div>))}
            
            </Carousel>
          </div>
          <div className="buttonAddProduct">
            <Button
              id="buttonProductLg"
              type="primary"
              onClick={showAddProduct}
              icon={<PlusCircleOutlined />}
              size={"large"}
            >
              {t("add")}
            </Button>
            <ModalAddProduct visible={visible} hide={hideAddProduct} />
          </div>
        </div>
      ) : (
        <div
        className="smallWithoutProduct">
          <div className="titleWithoutProduct">
            <Title level={2}> {t("myProducts")}</Title>
          </div>
          <div className="smallDivCarousel">
          <Carousel autoplay effect="fade">
            {imgCarousel.map (img => (
              <div key={img.id} className="imgCarousel" >
                <Image preview={false} src={img.uri} alt="" />     
              </div>))}
            
            </Carousel>
          </div>
          <div className="buttonSmallWithoutProduct">
            <Button
              id="buttonProductSl"
              type="primary"
              onClick={showAddProduct}
              icon={<PlusCircleOutlined />}
              size={"small"}
            >
              {t("add")}
            </Button>
            <ModalAddProduct visible={visible} hide={hideAddProduct} />
          </div>
        </div>
      )}
    </>
  );
  }
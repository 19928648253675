import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
// import XHR from "i18next-xhr-backend";

import translationEng from "./locales/en/translation.json";
import loginEng from "./locales/en/loginComponent.json";
import dashboardEng from "./locales/en/dashboardComponent.json";
import productsEng from "./locales/en/productsComponent.json";
import homeEng from "./locales/en/homeComponent.json";
import notFoundEng from "./locales/en/notFoundComponent.json";
import profileEng from "./locales/en/profileComponent.json";
import myProfileCardEng from "./locales/en/myProfileCardComponent.json";
import savEng from "./locales/en/savComponent.json";
import signupEng from "./locales/en/signupComponent.json";
import tshirtEng from "./locales/en/tshirtComponent.json";
import addproductEng from "./locales/en/addproductComponent.json";
import modalTeeshirtEng from "./locales/en/modalTeeshirtComponent.json";
import forgotPasswordEng from "./locales/en/forgotPasswordComponent.json";
import productInfoEng from "./locales/en/productInfoComponent.json";

import translationFre from "./locales/fre/translation.json";
import loginFre from "./locales/fre/loginComponent.json";
import dashboardFre from "./locales/fre/dashboardComponent.json";
import productsFre from "./locales/fre/productsComponent.json";
import homeFre from "./locales/fre/homeComponent.json";
import notFoundFre from "./locales/fre/notFoundComponent.json";
import profileFre from "./locales/fre/profileComponent.json";
import myProfileCardFre from "./locales/fre/myProfileCardComponent.json";
import savFre from "./locales/fre/savComponent.json";
import signupFre from "./locales/fre/signupComponent.json";
import tshirtFre from "./locales/fre/tshirtComponent.json";
import addproductFre from "./locales/fre/addproductComponent.json";
import modalTeeshirtFre from "./locales/fre/modalTeeshirtComponent.json";
import forgotPasswordFre from "./locales/fre/forgotPasswordComponent.json";
import productInfoFre from "./locales/fre/productInfoComponent.json";

import translationDe from "./locales/de/translation.json";
import loginDe from "./locales/de/loginComponent.json";
import dashboardDe from "./locales/de/dashboardComponent.json";
import productsDe from "./locales/de/productsComponent.json";
import homeDe from "./locales/de/homeComponent.json";
import notFoundDe from "./locales/de/notFoundComponent.json";
import profileDe from "./locales/de/profileComponent.json";
import myProfileCardDe from "./locales/de/myProfileCardComponent.json";
import savDe from "./locales/de/savComponent.json";
import signupDe from "./locales/de/signupComponent.json";
import tshirtDe from "./locales/de/tshirtComponent.json";
import addproductDe from "./locales/de/addproductComponent.json";
import modalTeeshirtDe from "./locales/de/modalTeeshirtComponent.json";
import forgotPasswordDe from "./locales/de/forgotPasswordComponent.json";
import productInfoDe from "./locales/de/productInfoComponent.json";

import translationEs from "./locales/es/translation.json";
import loginEs from "./locales/es/loginComponent.json";
import dashboardEs from "./locales/es/dashboardComponent.json";
import productsEs from "./locales/es/productsComponent.json";
import homeEs from "./locales/es/homeComponent.json";
import notFoundEs from "./locales/es/notFoundComponent.json";
import profileEs from "./locales/es/profileComponent.json";
import myProfileCardEs from "./locales/es/myProfileCardComponent.json";
import savEs from "./locales/es/savComponent.json";
import signupEs from "./locales/es/signupComponent.json";
import tshirtEs from "./locales/es/tshirtComponent.json";
import addproductEs from "./locales/es/addproductComponent.json";
import modalTeeshirtEs from "./locales/es/modalTeeshirtComponent.json";
import forgotPasswordEs from "./locales/es/forgotPasswordComponent.json";
import productInfoEs from "./locales/es/productInfoComponent.json";

// // FIX ERROR LOADING i18
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    // react: {
    //   wait: true,
    //   useSuspense: false,
    // },
    resources: {
      en: {
        translations: translationEng,
        login: loginEng,
        dashboard: dashboardEng,
        products: productsEng,
        home: homeEng,
        notFound: notFoundEng,
        profile: profileEng,
        myProfileCard: myProfileCardEng,
        sav: savEng,
        signup: signupEng,
        tshirt: tshirtEng,
        addproduct: addproductEng,
        modalTeeshirt: modalTeeshirtEng,
        forgotPassword: forgotPasswordEng,
        productInfo: productInfoEng,
      },

      fre: {
        translations: translationFre,
        login: loginFre,
        dashboard: dashboardFre,
        products: productsFre,
        home: homeFre,
        notFound: notFoundFre,
        profile: profileFre,
        myProfileCard: myProfileCardFre,
        sav: savFre,
        signup: signupFre,
        tshirt: tshirtFre,
        addproduct: addproductFre,
        modalTeeshirt: modalTeeshirtFre,
        forgotPassword: forgotPasswordFre,
        productInfo: productInfoFre,
      },

      de: {
        translations: translationDe,
        login: loginDe,
        dashboard: dashboardDe,
        products: productsDe,
        home: homeDe,
        notFound: notFoundDe,
        profile: profileDe,
        myProfileCard: myProfileCardDe,
        sav: savDe,
        signup: signupDe,
        tshirt: tshirtDe,
        addproduct: addproductDe,
        modalTeeshirt: modalTeeshirtDe,
        forgotPassword: forgotPasswordDe,
        productInfo: productInfoDe,
      },

      es: {
        translations: translationEs,
        login: loginEs,
        dashboard: dashboardEs,
        products: productsEs,
        home: homeEs,
        notFound: notFoundEs,
        profile: profileEs,
        myProfileCard: myProfileCardEs,
        sav: savEs,
        signup: signupEs,
        tshirt: tshirtEs,
        addproduct: addproductEs,
        modalTeeshirt: modalTeeshirtEs,
        forgotPassword: forgotPasswordEs,
        productInfo: productInfoEs,
      },
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;

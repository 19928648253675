import { useState, useEffect } from "react";
import { Result,message,Select,Button,Spin,Divider,Form,Row,Input,Col,Typography,Layout } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useProduct } from "../context/productContext";
import { IRegisterProduct } from "../Interface/IProduct";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { TextArea } = Input;

interface IOptionSelect {
  label: string;
  value: string;
}

interface ISavRequestForm {
  message: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function Sav() {
  const { t } = useTranslation("sav");

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<IRegisterProduct>();
  const [selectOptions, setSelectOptions] = useState<IOptionSelect[]>([]);
  const [success, setSuccess] = useState(false);
  const userProduct = useProduct()?.userProductList;
  const launchSavRequest = useProduct()!.newSavRequest;
  const history = useHistory();
  const [parameter, setParameter] = useState<string | null>(
    useQuery().get("id")
  );
  const msgDetail = t("detail");

  useEffect(() => {
    if (parameter && userProduct && selectOptions.length) {
      const productId = selectOptions.find(
        (product) => product.label === parameter
      );
      if (productId?.value)
        setSelectedProduct(userProduct.get(productId?.value));
      setParameter(null);
    }
  }, [parameter, userProduct, selectOptions]);

  useEffect(() => {
    if (userProduct !== undefined && userProduct.size > 0) {
      setSelectOptions(
        Array.from(userProduct).map(([label, value]) => ({
          label: value.serialNumber,
          value: value.id,
        }))
      );
    }
  }, [userProduct, setSelectOptions]);

  useEffect(() => {
    if (success) {
      resetTheWholeThing();
    }
  }, [success]);

  useEffect(() => {
    if (selectedProduct !== undefined) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [selectedProduct, setButtonDisable]);

  const resetTheWholeThing = () => {
    setConfirmLoading(false);
    setButtonDisable(true);
  };

  function handleGoBackHomeClick() {
    history.push("/");
    setSuccess(false);
  }

  async function onFinishForm(value: ISavRequestForm) {
    setConfirmLoading(true);
    if (selectedProduct)
      try {
        await launchSavRequest(selectedProduct, value.message);
      } catch (err) {
        setConfirmLoading(false);
        message.error(t("error1"));
      }
    else {
      setConfirmLoading(false);
      message.error(t("error2"));
    }
    setSuccess(true);
  }
  const onFinishFormFailed = () => {};

  const selectOnChange = (value: any) => {
    if (userProduct) {
      setSelectedProduct(userProduct.get(value));
    }
  };
  return (
    <Layout>
      {success && (
        <div className="layoutSav">
          <div>
            <Result icon={<CheckCircleOutlined />} status="success" />
          </div>
          <div>
            <Title>{t("thanks")} </Title>
          </div>
          <div>
            <Title level={2}>{t("receive")}</Title>
          </div>

          <div className="layoutButton" style={{ paddingTop: "50px" }}>
            <Button type={"primary"} onClick={handleGoBackHomeClick}>
              {t("back")}
            </Button>
          </div>
        </div>
      )}
      {!success && (
        <Row
          align={"top"}
          justify={"center"}
          className="rowSav"
        >
          <Col flex={1}>
            <div className="topSav">
              <Title className="titleSav" level={2}>
                {t("newRequest")}{" "}
              </Title>
              <Form layout={"vertical"}>
                <Form.Item label={t("serialNum")}>
                  <Select
                    defaultValue={parameter ? parameter : ""}
                    showSearch
                    placeholder={t("select")}
                    onChange={selectOnChange}
                    options={selectOptions}
                    filterOption={(input, option) =>
                      option?.value
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  ></Select>
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col flex={2}>
            <div className="informationSav" >
              <Title className="titleInformationSav" level={2}>
                {t("productInfo")}
              </Title>
              <Form layout={"vertical"}>
                <Row
                  gutter={32}
                  justify={"center"}
                  className="rowSav"
                >
                  <Col flex={"auto"}>
                    <Form.Item
                      initialValue={
                        selectedProduct ? selectedProduct.serialNumber : ""
                      }
                      label={t("serialNum")}
                    >
                      <Input
                        value={
                          selectedProduct ? selectedProduct.serialNumber : ""
                        }
                        className="inputSav"
                        disabled={true}
                      />
                    </Form.Item>
                    <Form.Item
                      initialValue={
                        selectedProduct ? selectedProduct.productName : ""
                      }
                      label={t("productName")}
                    >
                      <Input
                        value={
                          selectedProduct ? selectedProduct.productName : ""
                        }
                        className="inputSav"
                        disabled={true}
                      />
                    </Form.Item>
                    <Form.Item
                      initialValue={
                        selectedProduct ? selectedProduct.radio : ""
                      }
                      label="Radio"
                    >
                      <Input
                        value={selectedProduct ? selectedProduct.radio : ""}
                        className="inputSav"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col flex={"auto"}>
                    <Form.Item
                      initialValue={
                        selectedProduct ? selectedProduct.productType : ""
                      }
                      label="Type"
                    >
                      <Input
                        value={
                          selectedProduct ? selectedProduct.productType : ""
                        }
                        className="inputSav"
                        disabled={true}
                      />
                    </Form.Item>
                    <Form.Item
                      initialValue={
                        selectedProduct ? selectedProduct.versionFw : ""
                      }
                      label="Version FW"
                    >
                      <Input
                        value={selectedProduct ? selectedProduct.versionFw : ""}
                        className="inputSav"
                        disabled={true}
                      />
                    </Form.Item>
                    <Form.Item
                      initialValue={
                        selectedProduct ? selectedProduct.versionHw : ""
                      }
                      label="Version Hw"
                    >
                      <Input
                        value={selectedProduct ? selectedProduct.versionHw : ""}
                        className="inputSav"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div className="informationSav">
                <Title level={2}>{t("msg")}</Title>
              </div>
              <Form
                layout={"vertical"}
                className="formSav"
                name="basic"
                onFinish={onFinishForm}
                onFinishFailed={onFinishFormFailed}
              >
                <Form.Item
                  name="message"
                  rules={[{ required: true, message: msgDetail }]}
                >
                  <TextArea
                    disabled={selectedProduct ? false : true}
                    placeholder={t("msg")}
                    autoSize={{ minRows: 3, maxRows: 12 }}
                  />
                </Form.Item>
                <Button
                  className="buttonSav"
                  size="large"
                  htmlType="submit"
                  type="primary"
                  disabled={buttonDisable}
                >
                  {confirmLoading && <Spin />} {!confirmLoading && t("send")}{" "}
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      )}
    </Layout>
  );
}
<Divider>You</Divider>;

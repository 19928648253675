import { Button, Typography, Image, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import fourOFour from "../images/404.svg";
import logo from "../images/logo_light_nodonpro.svg";
import { useTranslation } from "react-i18next";
const { Title } = Typography;

export function NotFound() {
  const { t } = useTranslation("notFound");

  const history = useHistory();
  const bringBackToHome = () => {
    history.push("/");
  };

  return (
    <Row
      className="rowNotFound"
      justify="center"
      align="middle"
    >
      <Col>
        <Image className="imageNotFound" preview={false} src={logo}>
        </Image>
      </Col>
      <Col>
        <Image className="imageNotFound" preview={false} src={fourOFour}>
        </Image>
      </Col>
      <Col className="titleNotFound">
        <Title>OUPS !</Title>
        <Title level={2}>{t("notFound")}</Title>
      </Col>
      <Col className="colButtonNotFound">
        <Button
          type="primary"
          onClick={bringBackToHome}
          className="login-form-button"
        >
          {t("safePlace")}
        </Button>
      </Col>
    </Row>
  );
}

import React, {useCallback,useContext,useEffect,useMemo,useState,} from "react";
import { IRegisterProduct,IProductInfo,IUserProduct,IUserProductInfoLocalize } from "../Interface/IProduct";
import { ISavRequest } from "../Interface/ISavRequest";
import { firestore } from "../myFirebase";
import { useAuth } from "./AuthContext";
import i18n from "../i18n";

const ProductContext = React.createContext<ContextValue>(undefined);

type Props = {
  children: React.ReactNode;
};

export type ProviderValue = {
  userProductList: Map<string, IUserProduct>;
  userProductInfoLocalize: IUserProductInfoLocalize[] | null;
  userDataSourceProducts: IUserProduct[] | null;
  newSavRequest: (
    product: IRegisterProduct,
    message: string
  ) => Promise<void> | null;
  enableTshirt: boolean;
};

export type ContextValue = ProviderValue | undefined;

export function useProduct() {
  return useContext(ProductContext);
}

export function ProductsProvider({ children }: Props) {
  const user = useAuth()!.currentUser;
  const userInfo = useAuth()!.userInfo;
  const [registeredProducts, setRegisteredProducts] = useState<Map<string, IRegisterProduct>>(new Map()); // change type here !
  const [productInfo, setProductInfos] = useState<Map<string, IProductInfo>>(new Map()); // change type here !
  const [userProductList, setUserProductList] = useState<Map<string, IUserProduct>>(new Map()); // change type here !
  const [enableTshirt, setEnableTshirt] = useState(false);
  const [userDataSourceProducts, setDataSourceProduct] = useState<IUserProduct[] | null>(null);
  const [userProductInfoLocalize, setUserProductInfoLocalize] = useState<IUserProductInfoLocalize[]>([]);

  useEffect(() => {
    if (user) {
      const docTshirt = firestore.collection("tshirt").doc(user.uid);
      const unsubscribe = docTshirt.onSnapshot((querySnap) => {
        if (!querySnap.exists) {
          setEnableTshirt(true);
        } else {
          setEnableTshirt(false);
        }
      });
      return unsubscribe;
    }
  }, [user]);

  useMemo(() => {
    if (userProductList.size) {
      const array = [...userProductList.values()];
      array.sort(function (a, b) {
        return b.activationDate.toMillis() - a.activationDate.toMillis();
      });
      setDataSourceProduct(array);
    } else {
      setDataSourceProduct(null);
    }
  }, [userProductList]);

  useEffect(() => {
    if (user) {
      const unsubscribe = firestore
        .collection("registeredProducts")
        .where("ownerUid", "==", user.uid)
        .onSnapshot((querySnap) => {
          setRegisteredProducts(
            new Map(
              querySnap.docs.map((d) => [
                d.id,
                d.data() as Readonly<IRegisterProduct>,
              ])
            )
          );
        });
      return unsubscribe;
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const unsubscribe = firestore
        .collection("productInfo")
        .onSnapshot((querySnap) => {
          setProductInfos(
            new Map(
              querySnap.docs.map((d) => [
                d.id,
                d.data() as Readonly<IProductInfo>,
              ])
            )
          );
        });
      return unsubscribe;
    }
  }, [user]);

  function newSavRequest(product: IRegisterProduct, message: string) {
    if (user && userInfo.userInfo) {
      const savRequest = {} as ISavRequest;
      savRequest.product = product;
      savRequest.userInfo = userInfo.userInfo;
      savRequest.message = message;
      savRequest.ownerUid = user.uid;
      return firestore.collection("savRequest").doc().set(savRequest);
    }
    return null;
  }

  const addLocalizeProduct = useCallback(() => {
    var currentLanguage = i18n.language;
    const temporaryProductLocalize = [] as IUserProductInfoLocalize[];
    temporaryProductLocalize.length = 0;
    if (productInfo.size) {
      const arrRegProductInfo = [...productInfo.values()];
      arrRegProductInfo.forEach((oldProductInfo: IProductInfo) => {
        const newLocalizeProduct = {
          reference: "",
          publicName: "",
          photoUrl: "",
          userGuideUrl: "",
          powerSupply: 0,
          frequency: 0,
          range: 0,
          temperature: {
            minTemperature: 0,
            maxTemperature: 0,
          },
          power: 0,
          output: 0,
          dimension: {
            length: 0,
            width: 0,
            height: 0,
          },
          wiring: "",
          nodonProWebsite: "",
          description: "",
        } as IUserProductInfoLocalize;
        newLocalizeProduct.reference = oldProductInfo.reference;
        if (currentLanguage in oldProductInfo.publicName) {
          // @ts-ignore TODO ALexis TS Check
          newLocalizeProduct.publicName =oldProductInfo.publicName[currentLanguage];
        } else {
          newLocalizeProduct.publicName = oldProductInfo.publicName.en;
        }
        newLocalizeProduct.photoUrl = oldProductInfo.photoUrl;
        if (currentLanguage in oldProductInfo.userGuideUrl) {
          // @ts-ignore TODO ALexis TS Check
          newLocalizeProduct.userGuideUrl =oldProductInfo.userGuideUrl[currentLanguage];
        } else {
          newLocalizeProduct.userGuideUrl = oldProductInfo.userGuideUrl.en;
        }
        newLocalizeProduct.powerSupply = oldProductInfo.powerSupply;
        newLocalizeProduct.frequency = oldProductInfo.frequency;
        newLocalizeProduct.range = oldProductInfo.range;
        newLocalizeProduct.temperature.minTemperature =
          oldProductInfo.temperature.minTemperature;
        newLocalizeProduct.temperature.maxTemperature =
          oldProductInfo.temperature.maxTemperature;
        newLocalizeProduct.power = oldProductInfo.power;
        newLocalizeProduct.output = oldProductInfo.output;
        newLocalizeProduct.dimension.length = oldProductInfo.dimension.length;
        newLocalizeProduct.dimension.width = oldProductInfo.dimension.width;
        newLocalizeProduct.dimension.height = oldProductInfo.dimension.height;

        newLocalizeProduct.nodonProWebsite = oldProductInfo.nodonProWebsite;
        if (currentLanguage in oldProductInfo.wiring) {
          // @ts-ignore TODO ALexis TS Check
          newLocalizeProduct.wiring = oldProductInfo.wiring[currentLanguage];
        } else {
          newLocalizeProduct.wiring = oldProductInfo.wiring.en;
        }
        if (currentLanguage in oldProductInfo.description) {
          // @ts-ignore TODO ALexis TS Check
          newLocalizeProduct.description = oldProductInfo.description[currentLanguage];
        } else {
          newLocalizeProduct.description = oldProductInfo.description.en;
        }
        temporaryProductLocalize.push(newLocalizeProduct);
      });
      setUserProductInfoLocalize(temporaryProductLocalize);
    }
  }, [productInfo, setUserProductInfoLocalize]);

  const mergeProducts = useCallback(() => {
    if (registeredProducts.size && userProductInfoLocalize.length) {
      const arrRegProduct = [...registeredProducts.values()];

      const mergedArray: IUserProduct[] = [];

      arrRegProduct.forEach((elem: IRegisterProduct) => {
        const foundPrInfo = userProductInfoLocalize.find(
          (prdInfo) => prdInfo.reference === elem.reference
        );
        if (foundPrInfo) {
          mergedArray.push({ ...elem, ...foundPrInfo });
        } else {
          mergedArray.push(elem);
        }
      });

      setUserProductList(new Map(mergedArray.map((obj) => [obj.id, obj])));
    }
  }, [registeredProducts, userProductInfoLocalize]);

  useEffect(() => {
    mergeProducts();
  }, [registeredProducts, userProductInfoLocalize, mergeProducts]);

  useEffect(() => {
    addLocalizeProduct();
  }, [productInfo, addLocalizeProduct]);

  const value = {
    userProductInfoLocalize,
    userProductList,
    userDataSourceProducts,
    newSavRequest,
    enableTshirt,
  };
  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
}

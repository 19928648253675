import { useState } from "react";
import { Typography, Alert, Row, Col, Card, Form, Input, Button } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import logo from "../images/logo_light_nodonpro.svg";

const { Title } = Typography;

export function Login() {
  const { t } = useTranslation("login");

  const login = useAuth()!.login;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const msgEmail = t("email");
  const msgMdp = t("pwd");

  async function handleLogin(values: any) {
    setError("");

    try {
      setLoading(true);
      await login(values.email, values.password);
      history.push("/");
    } catch (e) {
      setError(t("errorLogin"));
    }
    setLoading(false);
  }

  return (
    <Row className="rowLogin" justify="center" align="middle">
      <Col xs={24} sm={16} md={14} lg={10} xl={8}>
        <div className="logo-login-img" >
          <img src={logo} alt="logo" />
        </div>
        <Card className="card-login" bordered={false}>
          <Title className="title-card-login" level={2}>
            {t("loginTitle")}
          </Title>
          {error && <Alert message={error} type="error" />}

          <Form
            name="normal_login"
            className="login-form"
            onFinish={handleLogin}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: msgEmail }]}
              hasFeedback
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: msgMdp }]}
              hasFeedback
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item className="item-pwd-login">
              <Link to="/forgot-password">
                {t("forgotPwd")}
              </Link>
            </Form.Item>
            <Form.Item className="button-submit-login">
              <Button
                type="primary"
                disabled={loading}
                htmlType="submit"
                className="login-form-button"
              >
                {t("login")}
              </Button>
              {t("account")} <Link to="/signup"> {t("register")} </Link>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

import React, { useContext, useState, useEffect } from "react";
import firebase from "firebase";
import { auth, firestore } from "../myFirebase";
import { IUser } from "../Interface/IUser";

const AuthContext = React.createContext<ContextValue>(undefined);

type Props = {
  children: React.ReactNode;
};

interface userInfos {
  newUser: boolean;
  userInfo: IUser | null;
}

export type ProviderValue = {
  currentUser: firebase.User | null | undefined;
  userInfo: userInfos;
  signup: (user: IUser) => Promise<firebase.auth.UserCredential>;
  login: (
    email: string,
    password: string
  ) => Promise<firebase.auth.UserCredential>;
  passwordReset: (email: string) => Promise<void>;
  logout: () => Promise<void>;
};

export type ContextValue = ProviderValue | undefined;

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: Props) {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<userInfos>({
    newUser: false,
    userInfo: null,
  });

  function signup(newUserInfo: IUser) {
    const password = newUserInfo.password!;
    delete newUserInfo.password;
    delete newUserInfo.passwordRepeat;
    setUserInfo({ newUser: true, userInfo: newUserInfo });
    return auth.createUserWithEmailAndPassword(newUserInfo.email, password);
  }

  function login(email: string, password: string) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    setUserInfo({ newUser: false, userInfo: null });
    return auth.signOut();
  }

  function passwordReset(email: string) {
    return auth.sendPasswordResetEmail(email);
  }

  function newUserSignup() {
    if (userInfo.userInfo && currentUser) {
      firestore
        .collection("users")
        .doc(currentUser.uid)
        .set(userInfo.userInfo)
        .then(function () {
          setUserInfo((prevUserInfo) => ({ ...prevUserInfo, newUser: false }));
        })
        .catch(function (error) {});
    }
  }

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = firestore
        .collection("users")
        .doc(currentUser.uid)
        .onSnapshot((snap) => {
          setUserInfo((prevUserInfo) => ({
            ...prevUserInfo,
            userInfo: snap.data() as IUser,
          }));
        });
      return unsubscribe;
    }
  }, [currentUser]);

  useEffect(() => {
    if (userInfo.newUser && currentUser) {
      newUserSignup();
    }
  }, [userInfo.newUser, currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentUser) {
      firestore
        .collection("users")
        .doc(currentUser!.uid)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            setUserInfo((prevUserInfo) => ({
              userInfo: doc.data() as IUser,
              newUser: false,
            }));
          } else {
          }
        })
        .catch(function (error) {});
    }
  }, [currentUser]);

  useEffect(() => {
    const unsusbscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsusbscribe;
  }, []);

  const value = {
    currentUser,
    userInfo,
    signup,
    login,
    logout,
    passwordReset,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

import { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { functions } from "../myFirebase";
import {Result,Alert,Spin,Input,Button,Divider,Modal,Typography,Image,} from "antd";
import { useTranslation } from "react-i18next";
import SIN_PRO_SN_700x400px from "../images/SIN_PRO_SN_700x400px.png";

const serialNumberMinmumSize = 6;

interface ImodalProps {
  visible: boolean;
  hide: () => void;
}

const { Title } = Typography;

export function ModalAddProduct(props: ImodalProps) {
  const { t } = useTranslation("addproduct");

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setsuccess] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [sn, setSn] = useState("");
  const hide = props.hide;

  const closingModal = () => {
    hide();
    setConfirmLoading(false);
    setError(false);
    setsuccess(false);
    setButtonDisable(true);
    setSn("");
  };

  useEffect(() => {
    if (sn.length >= serialNumberMinmumSize) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [sn]);

  useEffect(() => {
    if (success === true) {
      setButtonDisable(false);
      setTimeout(() => {
        hide();
        setTimeout(() => {
          setSn("");
          setConfirmLoading(false);
          setError(false);
          setsuccess(false);
          setButtonDisable(true);
        }, 200);
      }, 2000);
    }
  }, [success, hide]);

  const handleOk = () => {
    setConfirmLoading(true);
    setError(false);
    functions
      .httpsCallable("addProduct")({ sN: sn })
      .then((result) => {
        setsuccess(true);
        setConfirmLoading(false);
      })
      .catch((err) => {
        setError(true);
        setConfirmLoading(false);
      });
  };

  useEffect(() => {
    if (error === true) {
      setButtonDisable(error);
    }
  }, [error]);

  const onValueChangeUpdate = (e: any) => {
    if (e.target.value) {
      setSn(e.target.value);
    } else {
      setSn("");
    }
  };

  return (
    <Modal
      onCancel={closingModal}
      footer={null}
      keyboard={true}
      visible={props.visible}
      confirmLoading={confirmLoading}
    >
      <div id="generalDiv">
        <div id="titleDiv" >
          <Title> {t("add")}</Title>
        </div>
        <Divider />
        {!success && (
          <div  id="progressDiv" >
            {confirmLoading && <Alert message={t("progress")} type="info" />}
            {error && <Alert message={t("cannotAdd")} type="error" />}
          </div>
        )}
        {!success && (
          <div id="successDiv">
            <div>
              <p>{t("text")}</p>
            </div>
            <div >
              <Image
                preview={false}
                width={"100%"}
                height={"auto"}
                src={SIN_PRO_SN_700x400px}
              />
            </div>
            <div >
              <Input
                value={sn}
                size="large"
                placeholder={t("serialnum")}
                onChange={onValueChangeUpdate}
              />
            </div>
            <div>
              <Button
                size="large"
                type="primary"
                disabled={buttonDisable}
                onClick={handleOk}
                icon={!confirmLoading && <PlusOutlined />}
              >
                {confirmLoading && <Spin />} {!confirmLoading && "Add"}{" "}
              </Button>
            </div>
          </div>
        )}
        {success && (
          <div>
            <Result title={t("wellAdd")} status="success" />
          </div>
        )}
      </div>
    </Modal>
  );
}

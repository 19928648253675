import { useState, useEffect } from "react";
import { Row,Col,Radio,Divider,Form,Input,Result,Spin,Progress,Image,Typography,Card,Button,Modal } from "antd";
import { GiftOutlined, LockOutlined } from "@ant-design/icons";
import { useWindowSize } from "./utils/windowsSize";
import { useProduct } from "../context/productContext";
import { useAuth } from "../context/AuthContext";
import { motion } from "framer-motion";
import { functions } from "../myFirebase";
import tShirt from "../images/t-shirt.svg";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const nbTshirtReq = 10;

interface ITshirtProps {
  show: () => void;
  wait: boolean | undefined;
}

export function Tshirt(props: ITshirtProps) {
  const { t } = useTranslation("tshirt");
  const numberOfProduct = useProduct()?.userProductList.size;
  const [visible, setVisible] = useState(false);
  const [bigWindowsHeight, setBigWindowsHeight] = useState(false);
  const [mediumWindowsHeight, setMediumWindowsHeight] = useState(false);
  const [smallWindowsHeight, setSmallWindowsHeight] = useState(false);
  const size = useWindowSize();

  const ballStyle = {
    display: "block",
  };

  const bounceTransition = {
    y: {
      duration: 1.2,
      yoyo: Infinity,
      ease: "easeOut",
    },
  };

  useEffect(() => {
    if (size.height) {
      if (size.height < 620) {
        if (!smallWindowsHeight) {
          setSmallWindowsHeight(true);
        }
      } else {
        if (smallWindowsHeight) {
          setSmallWindowsHeight(false);
        }
      }
    }
  }, [size.height, smallWindowsHeight]);

  useEffect(() => {
    if (size.height) {
      if (size.height < 780) {
        if (!mediumWindowsHeight) {
          setMediumWindowsHeight(true);
        }
      } else {
        if (mediumWindowsHeight) {
          setMediumWindowsHeight(false);
        }
      }
    }
  }, [size.height, mediumWindowsHeight]);

  useEffect(() => {
    if (size.height) {
      if (size.height > 1200) {
        if (!bigWindowsHeight) {
          setBigWindowsHeight(true);
        }
      } else {
        if (bigWindowsHeight) {
          setBigWindowsHeight(false);
        }
      }
    }
  }, [size.height, bigWindowsHeight]);

  const showTshirtOrder = () => {
    setVisible(true);
  };

  const hideTshirtOrder = () => {
    setVisible(false);
  };

  if (numberOfProduct !== undefined && numberOfProduct < nbTshirtReq) {
    return (
      <Card
        id="cardTshirt"
        hoverable
        bodyStyle={{ minWidth: "30vh", height: "50vh" }}
        bordered={true}
      >
        <div className="divTshirt">
          <Title className="titleProgress"  level={2}>
            {t("get")}
          </Title>

          {bigWindowsHeight && (
            <Progress
              strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
              width={300}
              status="active"
              type="circle"
              percent={Math.round(numberOfProduct /nbTshirtReq *100)}
            />
          )}
          {!smallWindowsHeight && !bigWindowsHeight && (
            <Progress
              strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
              width={140}
              status="active"
              type="circle"
              percent={Math.round(numberOfProduct /nbTshirtReq *100)}
            />
          )}

          {smallWindowsHeight && !bigWindowsHeight && (
            <Progress percent={Math.round(numberOfProduct /nbTshirtReq *100)} size="small" />
          )}
          {!mediumWindowsHeight && (
            <div className="textAlign">
              <p className="addMoreTshirt">
                {t("add")} {nbTshirtReq - numberOfProduct} {t("more")}
              </p>
            </div>
          )}
        </div>
      </Card>
    );
  } else {
    return (
      <Card
      bodyStyle={{ minWidth: "30vh", height: "50vh" }}
      id="cardTshirt"
      bordered={true}
      >
        <div className="divTshirt">
          <div className="titleTshirt">
            <Title level={3}> {t("get")} </Title>
          </div>
          {!smallWindowsHeight && (
            <div className="animationTshirt">
              <motion.span
                style={ballStyle}
                transition={bounceTransition}
                animate={{ y: ["3%", "-3%", "0%", "0%", "0%", "0%", "0%"] }}
              >
                <Image
                  width={"100%"}
                  src={tShirt}
                >
                </Image>
              </motion.span>
            </div>
          )}
          {props.wait && (
            <div className="buttonTshirt">
              <Button type="primary" size={"large"} onClick={showTshirtOrder}>
                {t("send")}
              </Button>
              <TshirtModalDelivery visible={visible} hide={hideTshirtOrder} />
            </div>
          )}
        </div>
      </Card>
    );
  }
}

interface ITshirtModalProps {
  visible: boolean;
  hide: () => void;
}

interface ITshirtOrderForm {
  address: string;
  addressExtra?: string;
  city: string;
  email: string;
  name: string;
  phone: string;
  postalCode: string;
  size: string;
  surname: string;
}

function TshirtModalDelivery(props: ITshirtModalProps) {
  const { t } = useTranslation("modalTeeshirt");

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setsuccess] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const userInfo = useAuth()!.userInfo.userInfo;
  const hide = props.hide;
  const msgName = t("msgName");
  const msgEmail = t("msgEmail");
  const msgSurname = t("msgSurname");
  const msgPhone = t("msgPhone");
  const msgAdress = t("msgAdress");
  const msgCity = t("msgCity");
  const msgCp = t("msgCp");
  const msgSize = t("msgSize");
  const msgGetTshirt = t("msgGetTshirt");

  const closingModal = () => {
    hide();
    setTimeout(() => {
      setsuccess(false);
      setConfirmLoading(false);
      setError(false);
      setsuccess(false);
      setButtonDisable(false);
    }, 200);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  const onFinishForm = (values: ITshirtOrderForm) => {
    setConfirmLoading(true);
    setButtonDisable(true);
    functions
      .httpsCallable("orderThsirt")(values)
      .then((result) => {
        setsuccess(true);
        setConfirmLoading(false);
        setTimeout(() => {
          closingModal();
        }, 2000);
      })
      .catch((err) => {
        setError(true);
        setConfirmLoading(false);
        setTimeout(() => {
          closingModal();
        }, 2000);
      });
  };

  const onFinishFormFailed = (errorInfo: any) => {};

  return (
    <Modal
      onCancel={closingModal}
      footer={null}
      visible={props.visible}
      confirmLoading={confirmLoading}
    >
      <div className="divModalTshirt">
        <div className="resultDivTshirt">
          {!success ?? <Title> {t("where")} </Title>}
        </div>

        {success && (
          <div>
            <Result title={t("ontheway")} status="success" />
          </div>
        )}
        {error && (
          <div>
            <Result title={t("errorTshirt")} status="error" />
          </div>
        )}

        {!success && !error && (
          <div className="formDivTshirt">
            {userInfo ? (
              <>
                <Form
                  className="formModalTshirt"
                  layout={"vertical"}
                  {...formItemLayout}
                  name="basic"
                  onFinish={onFinishForm}
                  onFinishFailed={onFinishFormFailed}
                >
                  <Divider>{t("you")}</Divider>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        initialValue={userInfo.name}
                        label={t("name")}
                        name="name"
                        rules={[{ required: true, message: msgName }]}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        initialValue={userInfo.surname}
                        label={t("surname")}
                        name="surname"
                        rules={[{ required: true, message: msgSurname }]}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={14}>
                      <Form.Item
                        initialValue={userInfo.email}
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: msgEmail }]}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        initialValue={userInfo.phone}
                        label={t("phone")}
                        name="phone"
                        rules={[{ required: true, message: msgPhone }]}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider> {t("shipping")}</Divider>
                  <Form.Item
                    label={t("adress")}
                    name="address"
                    rules={[{ required: true, message: msgAdress }]}
                  >
                    <Input disabled={buttonDisable} />
                  </Form.Item>
                  <Form.Item label={t("extraAdress")} name="addressExtra">
                    <Input disabled={buttonDisable} />
                  </Form.Item>
                  <Row gutter={16}>
                    <Col span={14}>
                      <Form.Item
                        label={t("city")}
                        name="city"
                        rules={[{ required: true, message: msgCity }]}
                      >
                        <Input disabled={buttonDisable} />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        label={t("postalCode")}
                        name="postalCode"
                        rules={[{ required: true, message: msgCp }]}
                      >
                        <Input disabled={buttonDisable} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider> {t("pick")}</Divider>
                  <Form.Item
                    className="sizeModalTshirt"
                    label={t("size")}
                    name="size"
                    rules={[{ required: true, message: msgSize }]}
                  >
                    <Radio.Group disabled={buttonDisable}>
                      <Radio.Button value="M">M</Radio.Button>
                      <Radio.Button value="L">L</Radio.Button>
                      <Radio.Button value="XL">XL</Radio.Button>
                      <Radio.Button value="XXL">XXL</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Button
                    className="formModalTshirt"
                    size="large"
                    htmlType="submit"
                    type="primary"
                    disabled={buttonDisable}
                  >
                    {confirmLoading && <Spin />}{" "}
                    {!confirmLoading && <GiftOutlined />}{" "}
                    {!confirmLoading && msgGetTshirt}{" "}
                    {!confirmLoading && <GiftOutlined />}{" "}
                  </Button>

                  <Text type={"secondary"}> {t("cond")}</Text>
                </Form>
              </>
            ) : (
              <Result
                icon={<LockOutlined />}
                title={t("errorSupport")}
                extra={
                  <Button onClick={hide} type="primary">
                    Exit
                  </Button>
                }
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/functions";

if (process.env.REACT_APP_HP_FIREBASE_APP_CONFIG !== undefined) {
  firebase.initializeApp(
    JSON.parse(process.env.REACT_APP_HP_FIREBASE_APP_CONFIG)
  );
  firebase.analytics();
} else {
  console.log("ERROR : env var is missing for firebase initializeApp ");
}

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.app().functions("europe-west3");
export default firebase;

import React, { useContext,useState,} from "react";
import { IParameters } from "../Interface/IParameters";
import { firestore } from "../myFirebase";

type ProviderValue = {
    param: IParameters |undefined ;
};

const ParametersContext = React.createContext<ProviderValue | null >(null);

type Props = {
    children: React.ReactNode;
  };

export function useParameters() {
    return useContext(ParametersContext);
}

export function ParametersProvider({ children }: Props) { 
    const [param, setParam] =  useState<IParameters>();

    const paramList = firestore.collection('parameters').doc("imgs");

    paramList.get().then((doc ) => {
        if (doc.exists) {
            setParam(doc.data()as Readonly<IParameters>);
        } else {
            console.log("No such document!");
        }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });


    const value = {
        param,
    };
    return (
        
        <ParametersContext.Provider value={value}>{children}</ParametersContext.Provider>
    );
}



import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Image, Avatar, Table, Typography, Card, Button } from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { PlusCircleOutlined, AntDesignOutlined } from "@ant-design/icons";
import { useAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";
import { useProduct } from "../context/productContext";
import firebase from "../myFirebase";
import { IUserProduct } from "../Interface/IProduct";
import { Tshirt } from "./thsirt";
import { ModalAddProduct } from "./addProductModal";
import { MyProfileCard } from "./profileCardHome";
import { useTranslation } from "react-i18next";
import { minImgDefault } from "./utils/default";
import i18n from "../i18n";
import { useWindowSize } from "./utils/windowsSize";
import homeBottom from "../images/homeBottomImage.png";
import { ModalInfoProduct } from "./productInfo";

const { Title } = Typography;

function SavCard() {
  const { t } = useTranslation("sav");

  const history = useHistory();

  const onClickCard = () => {
    history.push("/sav");
  };

  return (
    <Card
      onClick={onClickCard}
      hoverable
      bodyStyle={{ minWidth: "30vh", height: "50vh", padding: "0" }}
      className="cardSavHome"
    >
      <div className="background-sav-card">
        <div className="text-sav-card">
          <Title id="title-sav-card"> {t("sav")} </Title>
        </div>
      </div>
    </Card>
  );
}

export function Home() {
  const { t } = useTranslation("home");

  const [visibleInfo, setVisibleInfo] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState<IUserProduct | null>(
    null
  );

  const showInfoProduct = (product: IUserProduct | null) => {
    setSelectedProduct(product);
    setVisibleInfo(true);
  };

  const hideInfoProduct = () => {
    setVisibleInfo(false);
  };
  //@ts-ignore
  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "partImage",
      width: "30px",
      responsive: ["md"] as Breakpoint[],
      align: "center" as "center",
      render: (r: any, image: IUserProduct) => (
        <Avatar
          size={{ xs: 24, sm: 36, md: 40, lg: 44, xl: 46, xxl: 50 }}
          icon={<AntDesignOutlined />}
          shape="square"
          src={image.photoUrl ? image.photoUrl : minImgDefault}
        />
      ),
    },
    {
      title: t("nameProduct"),
      key: "name",
      dataIndex: "productName",
      align: "center" as "center",
      width: "80px",
      ellipsis: true,
      render: (r: string, product: IUserProduct) => (
        <>
          <Button onClick={() => showInfoProduct( product)} type="link">
            {product.publicName ? product.publicName : product.productName}
          </Button>
          <ModalInfoProduct
            selectedProduct={selectedProduct}
            visible={visibleInfo}
            hide={hideInfoProduct}
          />
        </>
      ),
    },
    {
      title: "Radio",
      key: "radio",
      dataIndex: "radio",
      responsive: ["xxl"] as Breakpoint[],
      align: "center" as "center",
      width: "80px",
      render: (r: string) => r.toUpperCase(),
    },
    {
      title: t("warranty"),
      responsive: ["xxl"] as Breakpoint[],
      key: "endWarranty",
      dataIndex: "endOfGuarantee",
      align: "center" as "center",
      width: "70px",
      render: (r: firebase.firestore.Timestamp) =>
        r.toDate().toLocaleString(i18n.language).split(",")[0],
    },
    {
      title: t("guideTab"),
      key: "guide",
      dataIndex: "guide",
      align: "center" as "center",
      width: "70px",
      render: (r: string, product: IUserProduct) => (
        <Button type="text">
          <a
            target="_blank"
            rel="noreferrer"
            href={
              product.userGuideUrl ? product.userGuideUrl : product.userGuideUrl
            }
          >
            {t("userGuide")}{" "}
          </a>{" "}
        </Button>
      ),
    },
    {
      title: "Version",
      responsive: ["xxl"] as Breakpoint[],
      key: "version",
      dataIndex: "versionFw",
      align: "center" as "center",
      width: "120px",
    },
    {
      title: t("sav"),
      dataIndex: "",
      key: "sav",
      width: "50px",
      align: "center" as "center",
      responsive: ["md"] as Breakpoint[],
      render: (r: string, product: IUserProduct) => (
        <Link to={`/sav?id=${product.serialNumber}`}>
          <Button type="text">{t("declare")}</Button>
        </Link>
      ),
    },
  ];

  const [visible, setVisible] = useState(false);
  const userInfo = useAuth()!.userInfo.userInfo;
  const userDataSourceProducts = useProduct()?.userDataSourceProducts;
  const tshirt = useProduct()?.enableTshirt;
  const [showTshirt, setShowTshirt] = useState(false);
  const [showArray, setShowArray] = useState(false);
  const size = useWindowSize();
  const [smallWindowsHeight, setSmallWindowsHeight] = useState(false);
  const [smallWindowsWidth, setSmallWindowsWidth] = useState(false);

  useEffect(() => {
    if (size.height) {
      if (size.height < 650) {
        if (!smallWindowsHeight) {
          setSmallWindowsHeight(true);
        }
      } else {
        if (smallWindowsHeight) {
          setSmallWindowsHeight(false);
        }
      }
    }
  }, [size.height, smallWindowsHeight]);

  useEffect(() => {
    if (size.width) {
      if (size.width < 1000) {
        if (!smallWindowsWidth) {
          setSmallWindowsWidth(true);
        }
      } else {
        if (smallWindowsWidth) {
          setSmallWindowsWidth(false);
        }
      }
    }
  }, [size.width, smallWindowsWidth]);

  useEffect(() => {
    if (tshirt) {
      setTimeout(function () {
        setShowTshirt(true);
      }, 3000);
    } else {
      setTimeout(function () {
        setShowTshirt(false);
      }, 3000);
    }
  }, [tshirt]);

  useEffect(() => {
    if (userDataSourceProducts && userDataSourceProducts.length > 0) {
      setShowArray(true);
    } else {
      setShowArray(false);
    }
  }, [userDataSourceProducts]);

  const showAddProduct = () => {
    setVisible(true);
  };

  const hideAddProduct = () => {
    setVisible(false);
  };

  return (
    <div className="home-main-container">
      <div className="home-top-container">
        <div className="flex-container-home">
          <div className="flex-home-product-child">
            <Card
              bodyStyle={{ minWidth: "30vh", minHeight: "50vh" }}
              className="home-product-card"
            >
              <div id="home-description">
                <Title id="title-home-product-card">
                  {t("addProduct")}
                </Title>
                <p id="desc-home-product-card">
                  {t("description")}
                </p>

                <Button
                  id="button-home-product-card"
                  type="primary"
                  onClick={showAddProduct}
                  icon={<PlusCircleOutlined />}>
                  {t("addAProduct")}
                </Button>
                <ModalAddProduct visible={visible} hide={hideAddProduct} />
              </div>
            </Card>
          </div>
          {showTshirt && (
            <div className="flex-home-child">
              <Tshirt show={showAddProduct} wait={tshirt} />
            </div>
          )}
          <div className="flex-home-child">
            <SavCard />
          </div>
          <div className="flex-home-child">
            <MyProfileCard userInfo={userInfo} />
          </div>
        </div>
      </div>

      <div className="home-bottom-container">
        <div className="home-container-tab">
          <div className="title-home-container-tab">
            <Title level={2}> {t("lastProduct")}</Title>
          </div>

          {showArray ? (
            <Table
              className="table-products"
              size="middle"
              pagination={false}
              columns={columns}
              rowKey={(product) => product.id}
              dataSource={[...userDataSourceProducts!.slice(0, 5)]}
            />
          ) : (
            <Image
              id="imageHome"
              preview={false}
              src={homeBottom}
            />
          )}

          <div>
            <Link type={"secondary"} to="/products">
              <Title type={"secondary"} level={5}>
                {t("allProduct")}
              </Title>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
